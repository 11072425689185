<template>
  <div class="outer-page">
    <x-table
      :no-data-text="CA('maintenance_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
      v-model="model.show"
      @on-visible-change="modalShow"
      title="新增维修记录"
      :width="800"
    >
      <Form :label-width="100" ref="form" :model="form" :rules="rules">
        <Row>
          <!-- <Col span="11">
            <FormItem label="车辆ID" prop="carId">
              <Input
                clearable
                placeholder="请填写车辆ID"
                v-model="form.carId"
              ></Input>
            </FormItem>
          </Col> -->
          <Col span="11">
            <FormItem label="车牌号" prop="plate">
              <Input
                clearable
                placeholder="请填写车牌号"
                v-model="form.plate"
              ></Input>
            </FormItem>
          </Col>
          <Col span="11">
            <FormItem label="维修时间" prop="repairTime">
              <Input
                clearable
                placeholder="请填写维修时间"
                v-model="form.repairTime"
              ></Input>
            </FormItem>
          </Col>

          <Col span="11">
            <FormItem label="维修原因" prop="reason">
              <Input
                clearable
                v-model="form.reason"
                placeholder="请填写维修原因"
              ></Input>
            </FormItem>
          </Col>
          <Col span="11">
            <FormItem label="维修项目" prop="item">
              <Input
                clearable
                v-model="form.item"
                placeholder="请填写维修项目"
              ></Input>
            </FormItem>
          </Col>
          <Col span="11">
            <FormItem label="行驶公里数" prop="kilometers">
              <Input
                clearable
                v-model="form.kilometers"
                placeholder="请填写行驶公里数"
              ></Input>
            </FormItem>
          </Col>
          <Col span="11">
            <FormItem label="维修价格" prop="price">
              <Input
                clearable
                v-model="form.price"
                placeholder="请填写维修价格"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="备注" prop="remark">
              <Input
                clearable
                v-model="form.remark"
                type="textarea"
                :rows="3"
                placeholder="请填写备注"
              ></Input>
            </FormItem>
          </Col>
          <Col span="11">
            <FormItem label="维修单据">
              <upload-image
                :max="1"
                :clear-flag="!model.show"
                v-model="form.img"
              ></upload-image>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (model.show = false)">取消</Button>
      </p>
    </Modal>
    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </div>
</template>

<script>
import moment from "moment";
import pictureView from "@/components/picture_view";
import CU from "@/common/util";
export default {
  name: "",
  components: {
    pictureView,
  },
  data() {
    return {
      form: {
        carId: "", //车辆ID
        repairTime: "", //维修时间
        plate: "", //车牌号
        reason: "", //维修原因
        item: "", //维修项目
        kilometers: "", //行驶公里数
        price: "", //维修价格
        remark: "", //备注
        companyNo: "", //公司编码
        userId: "", //用户id
        id: "",
        img: "",
      },
      search_data: {},
      table: {
        columns: [
          // {
          //   title: "车辆ID",
          //   align: "center",
          //   key: "carId",
          // },
          {
            title: "车牌号",
            align: "center",
            key: "plate",
          },
          {
            title: "维修时间",
            align: "center",
            width: 200,
            key: "repairTime",
          },
          {
            title: "维修原因",
            align: "center",
            key: "reason",
          },
          {
            title: "维修项目",
            align: "center",
            key: "item",
          },
          {
            title: "行驶公里数",
            align: "center",
            key: "kilometers",
          },
          {
            title: "维修价格",
            align: "center",
            key: "price",
          },
          {
            title: "维修单据",
            width: 100,
            align: "center",
            render: (h, { row }) => {
              if (!row.img) return <span>--</span>;
              return (
                <div style="width:60px;height:60px;padding:4px">
                  <img
                    style="width:100%;height:100%;cursor:pointer"
                    src={this.getImgUrl(row.servicePath, row.img)}
                    onClick={() => this.lookPicture(row.servicePath + row.img)}
                  />
                </div>
              );
            },
          },
          {
            title: "备注",
            align: "center",
            width: 150,
            key: "remark",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {
                    //     this.CA("cars_view") && (
                    //     <a
                    //       style="margin-right: 10px"
                    //       onClick={() => this.editList(row)}
                    //     >
                    //       编辑
                    //     </a>
                    //   )
                  }
                  {this.CA("maintenance_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.deleteList(row)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      //验证规则
      rules: {
        plate: [
          { required: true, message: "请填写车牌号" },
          {
            validator(rule, value, callback) {
              let reg =
                /^([京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][1-9DF][1-9ABCDEFGHJKLMNPQRSTUVWXYZ]\d{3}[1-9DF]|[京津晋冀蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼渝川贵云藏陕甘青宁新][ABCDEFGHJKLMNPQRSTUVWXY][\dABCDEFGHJKLNMxPQRSTUVWXYZ]{5})$/;
              if (!reg.test(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的车牌号",
          },
        ],
        // telPhone: [
        //   { required: true, message: "请填写联系电话" },
        //   {
        //     validator(rule, value, callback) {
        //       if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
        //         callback(rule.message);
        //         return;
        //       }
        //       callback();
        //     },
        //     message: "手机号格式不正确",
        //     trigger: "change",
        //   },
        // ],
        // carId: [{ required: true, message: "请填写车辆ID" }],
        repairTime: [{ required: true, message: "请填写维修时间" }],
        reason: [{ required: true, message: "请填写维修原因" }],
        item: [{ required: true, message: "请填写维修项目" }],
        kilometers: [{ required: true, message: "请填写行驶公里数" }],
        price: [
          { required: true, message: "请填写维修价格" },
          {
            validator(rule, value, callback) {
              if (CU.validateNumber(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "请填写正确的维修价格",
          },
        ],
        // remark: [{ required: true, message: "请填写备注" }],
      },
      model: {
        show: false,
        info: {},
      },
      modal: {
        title: "",
        show: false,
        submitLoading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      picture: {
        show: false,
        src: "",
      },
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增维修记录",
            ca: "maintenance_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "车牌号",
              component: "input",
              field: "plate",
              defaultValue: "",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    //获取数据
    getList() {
      this.$post(this.$api.CAR_MAINTENANCE.LIST, {
        userId: localStorage.getItem("userId"),
        companyNo: localStorage.getItem("companyNo"),
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    //删除
    deleteList(row) {
      this.$post(this.$api.CAR_MAINTENANCE.DELETE, {
        id: row.id,
        userId: row.createUserId,
        companyNo: row.companyNo,
      }).then((res) => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    //编辑
    editList(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.id = row.id;
      this.model = {
        show: true,
        title: "编辑车辆信息",
        submitLoading: false,
      };
    },
    //添加
    add() {
      this.model = {
        show: true,
        title: "新增车辆信息",
        submitLoading: false,
      };
    },
    //提交
    submit() {
      let params = this.form;
      let url;
      if (params.id) {
        url = this.$api.CAR_MAINTENANCE.VIEW;
      } else {
        url = this.$api.CAR_MAINTENANCE.ADD;
      }
      // params.repairTime = moment(this.form.annualReciew).format("YYYY-MM-DD");
      params.userId = localStorage.getItem("userId");
      params.companyNo = localStorage.getItem("companyNo");
      let index = this.form.img ? this.form.img.indexOf("/", 10) : -1;
      if (index !== -1) {
        params.img = this.form.img.substring(index + 1);
      }
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "编辑成功！" : "添加成功！");
            this.getList();
            this.model.show = false;
          })
          .catch((e) => {});
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.$refs.form.resetFields();
      this.form.id = "";
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    search(data) {
      this.search_data = data;
      this.page.pageNo = 1;
      this.table.loading = true;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      };
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>